import React, { Component } from 'react';
import CreatableSelect from 'react-select/lib/Creatable';

function formatOptions(ops){
    return ops.map(o=>{
        return { value: o.value, label: o.label}
    })
}

const selectStyles = {
  loadingIndicator: provided => ({display: "none"}),
  dropdownIndicator: provided => ({display: "none"}),
  indicatorsContainer: provided => ({display: "none"}),
  singleValue: provided => ({...provided, overflow: "visible"}),
//   valueContainer: provided => ({...provided, background: "#ECECEC", border: "none"}),
	placeHolder: provided => ({...provided}),
	option: provided => ({...provided, "font-size": "18px"}),
	menu: provided => ({...provided, "box-shadow":"none", "border-radius":"4px", overflow: "hidden"}),
};

export default class Suggestion extends Component {

  constructor(props){
    super(props)
    this.state = {
      value: props.value
    }
  }

  handleChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    this.setState({
      value: newValue
    })
    this.props.onChange(newValue)
    //console.groupEnd();
  }
  handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  }
  handleFocus = (e) => {
		this.setState({
      className: "focus"
    })
  }
  handleBlur = (e) => {
		this.setState({
      className: ""
    })
  }
  handleCreateFormat = (inputValue) => {
    return "suggest \""+inputValue+"\""
  }

  render() {
      let ops = formatOptions(this.props.options)
      let value = this.state.value
    return (
      <CreatableSelect
       	className={"suggestion "+this.state.className}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={ops}
        autoSize={true}
        value={value}
        placeholder={this.props.placeholder}
        styles={selectStyles}
//         menuIsOpen={true}
        formatCreateLabel={this.handleCreateFormat}
      />
    );
  }
}