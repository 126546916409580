import React, { Component } from 'react';
import {Button, Grid, TextField, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ContentEditable from 'react-contenteditable'
import Dropbox from './dropbox'
import Keyboard from 'react-simple-keyboard';
import './keyboard.scss'
import 'react-simple-keyboard/build/css/index.css';
import Mercury from '@postlight/mercury-parser'

//import { Table, Button } from 'semantic-ui-react'


import Word from './word'
import TextareaAutosize from "react-textarea-autosize";
import { TextArea } from 'semantic-ui-react';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      color: 'black'
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
    root:{      
      color: 'purple'    
    }
  });

let lastInputChangeAt = 0;
let inputEndTimer = 0
const autoTranslateTimeThreshold = 500

class Input extends Component{
  constructor(props){
      super(props)
      this.props = props
      this.state= {
          tokens: props.tokens || [],
          text: props.text || "",
          layoutName: "default",
          languageMode: props.languageMode
      }
      this.onTextChanged = props.onChange
      this.onInputDelete = props.onInputDelete 
      //this.state.html = this.state.text;
      this.contentEditable = React.createRef(); 
      this.inputField = React.createRef(); 

  }
  
  keyPressAnywhere(event){
    window.avatar.Stop(6);
    window.waitForIdle();
  }
  
  componentDidMount(){
    document.addEventListener("keydown", this.keyPressAnywhere, false);

    if(this.props.translateInitial){
      this.updateText(this.state.text)
      setTimeout((function(){
				if(window.avatar) window.avatar.Process(6);
      }).bind(this), 500)
    }
  }
  
  componentDidUpdate(oldProps) {
    const newProps = this.props
    if(oldProps.languageMode !== newProps.languageMode) {
      this.setState({ languageMode: newProps.languageMode })
    }
    //this.inputField.current.focus()
  }
  
  deleteInput(e) {
   	this.setState({text:""}) 	
   	this.onInputDelete()
  }

  getInputHtml(){
    let html = this.state.text;
    //console.log(html, this.props.text)
    return html
  }

  handleFileLoaded(e){
    //window.avatar.Confirm(3);
    window.waitForIdle();
    let reader = e.currentTarget
    const content = reader.result;
    console.log(content)
    
    this.setState({
      text: content
    })
  }

  handleFileSelection(files){
    window.avatar.Upload(3);
    let f = files[0]
    let fileData = new FileReader();
    fileData.onloadend = ((e)=>{
      this.handleFileLoaded(e)
      if(this.props.onFileChange){
        this.props.onFileChange(files)
      }
    }).bind(this);
    fileData.readAsText(f);
    
  }

  handleContentEditable(e){
    const { tokens, text } = this.state
    const {
      target: { value },
    } = e
    this.updateText(value)
  }

  updateText(newText){
    //var expression = /^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var expression = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
    var rxIsLink = new RegExp(expression);
      
    if(newText.match(rxIsLink)){
      //window.avatar.Upload(3);
      const proxyurl = "https://cors-anywhere.herokuapp.com/";
      const link = newText
      this.setState({ 
        text: link //r.content 
      })
      Mercury.parse(proxyurl+link, {contentType: 'text' }).then(r => {
        this.props.onInputStopped(r.content, 0)
        this.setState({ 
          text: r.content //r.excerpt 
        })
      });
    }
    else {
      let datenow = Date.now()
      let timePassed = lastInputChangeAt == 0 ? 0 : datenow - lastInputChangeAt
      lastInputChangeAt = datenow
      clearTimeout(inputEndTimer)
      inputEndTimer = setTimeout(this.triggerInputStopped.bind(this), autoTranslateTimeThreshold)
      this.onTextChanged(newText, timePassed)
      this.setState({ 
        text: newText 
      })
    }
  }

  handleDynamicTextUpdated(newDynamicText){
    this.updateText(newDynamicText)
  }

  triggerInputStopped(){
    this.props.onInputStopped(this.state.text)
  }


  displayInputWords(){
    let t = this.props.results
    let completions = t.completions
    let toks = t.tokens
    if(!toks || !completions) return (<div></div>)
    if(Object.keys(toks).length==0 || Object.keys(completions).length==0) return (<div></div>)
    
    let word_ix = t.word_ix
    let tokenKeys = Object.keys(toks)
    let countOfWordsToDisplay = word_ix+1
    if(this.state.show_all_output){
      countOfWordsToDisplay = tokenKeys.length
    }
    
    let output = tokenKeys.map(i=>{
      let key = tokenKeys[i]
      let tok = toks[key]
      let completion = completions[key].completion
      return (<Word 
        key={i}
        translation={tok.token}
        completion={completion} 
        type={"source"}></Word>)
    })
    return output
  }

  onKeyboardKeyPress(btn){
    let text = this.getInputHtml()
    let isEdit = false
    let char = btn
    if(btn=="{bksp}"){
        text = text.substring(0, text.length-1)
        this.setState({
          text: text
        })
    }
    else if(btn=="{enter}"){
      char = "\n"
      isEdit = true
    }
    else if(btn=="{space}"){
      char = " "
      isEdit = true
    }
    else if(btn=="{tab}"){
      char = "\t"
      isEdit = true
    }
    else{
      isEdit = true
    }
      /**
     * If you want to handle the shift and caps lock buttons
     */
    if (btn === "{shift}" || btn === "{lock}"){
      this.handleShift()
      return
    }
  

    if(isEdit){
      text += char
      this.updateText(text)
    }
    return true
  }
  handleFocus (e) {
    if (this.props && this.props.onFocus) {
      this.props.onFocus(e);
    }
    
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
    if (!document.body.classList.contains('keyboard-open')) {
      document.body.classList.add('keyboard-open');
    } 
  }
  handleBlur (e) {
    if (this.props && this.props.onBlur) {
      this.props.onBlur(e);
    }
    
    if (document.body.classList.contains('keyboard-open')) {
      document.body.classList.remove('keyboard-open');
    }
  }
  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  render(){
        const { classes,results } = this.props;
        let inputHtml = this.getInputHtml()
        let shouldEdit = true
        const hasResults = !results || Object.keys(results.tokens).length>0
        if(hasResults){
          shouldEdit = false
        }
        const devMode = this.props.devMode
        let isDynamicText = false
        if(this.props.dynamicText){
          let dynamicText = this.props.dynamicText()
          let isDifferentText = false
          isDynamicText = true
          if(dynamicText){
            isDifferentText = inputHtml != dynamicText
            inputHtml = dynamicText
          }
          if(isDifferentText){
            setTimeout(()=>{
              this.handleDynamicTextUpdated(dynamicText)
            }, 1)
          }
        }
        const showKeyboard = this.props.showKeyboard()
        let languageMode = this.state.languageMode
        
        console.log("Input text: ", inputHtml)
        return (
          <div className="input-types">
            {<Dropbox onFilesSelected={this.handleFileSelection.bind(this)}>
              <TextareaAutosize
                id="textInput"
                placeholder={languageMode == "english" ? "⠠⠞⠽⠏⠑⠀⠐⠎⠹⠬⠲⠲⠲" : "Type something..." }
                value={inputHtml}
                innerref={this.contentEditable}
                ref={this.inputField}
                html={inputHtml}
                data-column="inputHtml"
                className="content-editable inputBox"
                onChange={this.handleContentEditable.bind(this)}
                onFocus={this.handleFocus.bind(this)}
                onBlur={this.handleBlur.bind(this)}
//                 autoFocus
                style={{ minHeight: 100 }}
              />
            </Dropbox>}
            {(false && devMode && hasResults) &&  (
            <div>
              {this.displayInputWords()}
            </div>
            )}
            {showKeyboard && (
              <Keyboard 
              ref={r => (this.keyboardRef = r)}
              layoutName={this.state.layoutName}
              onKeyPress={this.onKeyboardKeyPress.bind(this)}
              ></Keyboard>
                      )}
            
            <Button className={"btn btn-delete on-mobile"+(inputHtml==""?" hide":"")} variant="contained" color="primary" onClick={(()=>{ this.deleteInput() }).bind(this)}>
              Delete
            </Button>
          </div>           
          )
        
        
  }
}

export default withStyles(styles)(Input)