import React, { Component } from 'react';

export default class Switch extends Component {

  constructor ( props) {
    super( props );
		this.state = {
			isChecked: props.isChecked,
			text: props.text
		}
  }
	
	componentWillMount () {
		this.setState( { isChecked: this.props.isChecked, text: this.props.text , changeAction: this.props.handleChange  } );

	}

  render () {
    return(
        <div className="switch-container">
            <label>
                <span className="switch-text">{ this.state.text }</span>
                <input ref="switch" checked={ this.state.isChecked } onChange={ this.handleChange.bind(this) } className="switch" type="checkbox" />
                <div className="switch-nob">
                    <div></div>
                </div>
            </label>
        </div>
    );
  }

  handleChange () {
		this.setState( { isChecked: !this.state.isChecked } );
		if(this.state.changeAction) this.state.changeAction(this);
  }

}
