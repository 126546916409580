import {ApiEndpoint} from './consts'

export const apiservice = {
    req,
    redirect,
    sendFile
};

const url = window.location.href;
const arr = url.split("://");
const scheme = arr[0];
const protocol = scheme;
//Testing endpoints
//let endpoint = scheme + "://vaskovasilev.eu:5001";
//let endpoint = scheme + "://94.156.113.59:5001";
//Endpoint for Production 
let endpoint = ApiEndpoint

function req(urlx, opts){
    let options ={
        credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json' },
    }
    if(opts && opts.body) options['body'] = opts.body
    if( options['body'] )  options['body'] = JSON.stringify( options['body'])
    let addr = endpoint + urlx
    return fetch(addr, options);
}

function redirect(urlx){
    let addr = endpoint + urlx
    window.location = addr
}

function sendFile(file, urlx, fields){
    var data = new FormData()
    data.append('file', file[0])
    if(fields){
        var keys = Object.keys(fields)
        for(let i=0; i<keys.length; i++){
            let key = keys[i]
            let val = fields[key]
            data.append(key, val)
        }
    }
    let options ={
        credentials: 'include', method: 'POST',
        body: data
    }
    //if( options['body'] )  options['body'] = JSON.stringify( options['body'])
    let addr = endpoint + urlx
    return fetch(addr, options)
}