import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { apiservice } from './apiservice'
import Suggestion from './suggestion';

const styles = theme => ({
})

function labelize(c){
    c['label'] = c.value;// + " (" + c.backtranslation + ")"
    //c['value'] = c.value + " " + c.type
    return c
}

function getCorrectionType(c, token){
    if(c==token.liblouis) return 'liblouis'
    else return 'none'
}

class Word extends Component {

    constructor(props){
        super(props)
        this.state = {
            translation: props.translation,
            correctionValue: null
        }
    }

    getCorrectedTranslation(){
        let orig = this.props.translation;
        if(!this.state.correctionValue) return orig;
        let token = this.props.token
        let value = this.state.correctionValue
        let type = getCorrectionType(value, token)
        if(type=="duxbury") return token.duxbury;
        else if(type=="liblouis") return token.liblouis;
        else return orig
    }

    getOption(value, options){
        for(let i=0; i<options.length; i++){
            if(value==options[i].value) return options[i]
        }
    }

    getBacktranslation(){
        let orig = this.props.word
        if(!this.state.correctionValue) return orig;
        let token = this.props.token
        var value =this.state.correctionValue
        let type = getCorrectionType(value, token)
        if(type=="duxbury") return token.duxbury_backtranslation;
        else if(type=="liblouis") return token.liblouis_backtranslation;
        else return orig
    }

    getAccuracy(mlAccuracy){
        let orig = mlAccuracy
        if(!this.state.correctionValue) return orig;
        let token = this.props.token
        let value = this.state.correctionValue
        let type = getCorrectionType(value, token)
        if(type=="duxbury") return 100
        else if(type=="liblouis") return 100
        else return orig
    }

    onSuggestionInputClick(e){
        e.stopPropagation()
        e.preventDefault()
        console.log(e)
        return false
    }

    renderCorrectionOptions(){
        let optionState = this.getCorrectedTranslation()
        let token = this.props.token
        let corrections = Array.from(token.corrections || [])
        corrections.push({
            value: this.props.translation
        })
        console.log(optionState.length)
        //corrections.push({'type': 'none', 'value': token.t, 'backtranslation': token.backtranslation})
        //corrections.push({'type': 'duxbury', 'value': token.duxbury, 'backtranslation': token.duxbury_backtranslation})
        //corrections.push({'type': 'liblouis', 'value': token.liblouis, 'backtranslation': token.liblouis_backtranslation})

        corrections = corrections.map(c=> labelize(c))
        //if(selectedType=="none") delete corrections[0]
        //else if(selectedType=="duxbury") delete corrections[1]
        //else if(selectedType=="liblouis") delete corrections[2]        
        var options = corrections        
        let width = 5 + Math.max(optionState.length, 1) * 35
        var crvalue = this.getOption(optionState, corrections)
        console.log(crvalue)
        return (
            <div className="wrapper" style={{minWidth: width + 'px'}}>
                <Suggestion 
                    options={options}
                    placeholder={optionState}
                    value={crvalue}
                    onChange={this.onCorrectionSelected.bind(this)}
                />
            </div>
        )
    }

    onCorrectionSelected(e){
        let value = e.value
        //let values = value.split(" ")
        //let type = values[values.length -1]
        var token = this.props.token
        var isNew = e.__isNew__
        if(isNew){
            apiservice.req("/addSuggestion", { body: {
                type: '',
                correctedTo: value,
                text: token.token
            }})
                .then(r=>r.json())
        }else{
            apiservice.req("/setCorrection", { body: {
                type: '',
                correctedTo: value,
                text: token.token
            }})
                .then(r=>r.json())
        }
        
        this.setState({
            correctionValue: value
        })
    }

    renderWord(accuracy, completion){
        const type = this.props.type
        const isRef = type=='reference'
        const isSource = type=='source'
        const isTranslation = !isRef && !isSource
        const translation = this.props.translation
        console.log(isTranslation, accuracy, this.props.showCorrections, completion)
        if(isTranslation && (accuracy<100 || this.props.showCorrections) && completion==100){
            return this.renderCorrectionOptions()
        }
        else if(isTranslation && accuracy==100){
            return (<div className="brword">{translation}</div>)
        }else{
            return (<div className="brword">{translation}</div>)
        }
    }

    render(){
        const type = this.props.type
        let completion = this.props.completion
        if(completion<0) completion = 0;
        if(completion>100) completion = 100;

        let accuracy = this.props.accuracy
        
        let modifier = "good"
        if(accuracy<100){
            modifier = "bad"
        }
        if(completion<100){
            modifier = "processing"
        }
        const isRef = type=='reference'
        const isSource = type=='source'
        const isTranslation = !isRef && !isSource;
        const translation = this.props.translation
        const word = this.getBacktranslation()
        const simpleEdit = this.props.simpleEdit
        const devMode = this.props.devMode
        
        if(this.props.debug){
            console.log(accuracy, completion)
        }

        accuracy = (accuracy * (completion/100))
        let selectedAccuracy = this.getAccuracy(accuracy)
        if(selectedAccuracy==100 && completion==100){
            modifier = "good"
        }
        if(simpleEdit) modifier = ""        
        if(isSource) modifier += " src"
        return (
            <span className={"word " + modifier}>
                {this.renderWord(accuracy, completion)}

                { (devMode || (!isSource && !simpleEdit)) && 
                (
                <div className="txtword">{word}</div>
                )}
                {/*
 (devMode || (isSource && !simpleEdit)) && 
                (
                <div className="process">{completion} %</div>
                )
*/}
                
                { (devMode || (isTranslation && !simpleEdit)) && 
                (
                <div className="accuracy">{selectedAccuracy.toFixed(2)}%</div>
                )}
                
            </span>
        )
    }
}

export default withStyles(styles)(Word)