import React from 'react';
import FileDrop from 'react-file-drop';
import './filedrop.css'

class Dropbox extends React.Component {
  handleDrop = (files, event) => {
    this.props.onFilesSelected(files)
  }

  render() {    
    const styles = { border: '1px solid black', width: 600, color: 'black', padding: 20 };
    return (
      <div id="react-file-drop-demo" style={{styles}}>
        <FileDrop onDrop={this.handleDrop}>
          {this.props.children}
        </FileDrop>
      </div>
    );
  }
}

export default Dropbox